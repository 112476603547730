<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ currentPatient.nombre }} {{ currentPatient.surname }}
        </p>
        <button type="button" class="delete ml-4" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" style="display:flex">
        <div class=" mr-4">
          <!-- <v-gravatar
            :email="currentPatient.mail"
            alt="Nobody"
            default-img="mm"
            style="border-radius:50%; margin-top:20px;"
          /> -->
          <img
            v-if="currentPatient.foto"
            class="imgRigth"
            style="border-radius:5%; height: 240px; width: 240px; object-fit:cover;"
            :src="currentPatient.foto.url"
          />
          <h4>
            <strong
              >{{ currentPatient.nombre }} {{ currentPatient.surname }}</strong
            >
          </h4>
          <b v-if="currentPatient.is_staff == true">{{
            $t('MIEMBRO DEL STAFF')
          }}</b>
          <p>{{ currentPatient.dni }}</p>
          <p>{{ currentPatient.email }}</p>
          <!-- <p>Id de socio: {{ currentPatient.idsocio }}</p> -->
          <p>{{ $t('NºSoc') }}: {{ currentPatient.number }}</p>
          <p>{{ currentPatient.country_code }} {{ currentPatient.telefono }}</p>
          <p>
            {{ format_date(currentPatient.birthdate, 'dd/MM/yyyy') }}
          </p>
          <!-- <p>
            Último acceso al local:
            {{ format_date(currentPatient.login, 'hh:mm dd/MM/yyyy') }}
          </p> -->
          <p>
            {{ $t('Fin cuota') }}:
            {{ format_date(currentPatient.end_date, 'dd/MM/yyyy') }}
            <span
              v-if="
                currentPatient.end_date &&
                  currentPatient.end_date < Math.floor(Date.now() / 1000)
              "
            >
              ({{ $t('CADUCADA') }})
            </span>
          </p>

          <p>{{ $t('Tipo') }}: {{ translateType(currentPatient.type) }}</p>
          <p>
            {{ $t('Categoría') }}:
            {{
              currentPatient.category_member
                ? currentPatient.category_member.title
                : ''
            }}
          </p>
          <p>
            {{ $t('Fecha Alta') }}:
            {{ format_date(currentPatient.member_created, 'dd/MM/yyyy') }}
          </p>
          <p
            v-if="
              (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.monedero) &&
                walletPermission
            "
          >
            {{ $t('Saldo') }}: {{ currentPatient.monedero }} €
          </p>
          <p
            v-if="
              (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.monedero) &&
                walletPermission
            "
          >
            {{ $t('Monedero negativo') }}:
            {{ currentPatient.monedero_negativo ? $t('Sí') : $t('No') }}
          </p>
          <!-- <p>Puntos de recompensa: 0</p> -->
          <p>{{ $t('Previsión') }}: {{ currentPatient.consum_forecast }}</p>

          <p>
            {{ $t('Socio desde') }}:
            {{ format_date(currentPatient.member_created, 'dd/MM/yyyy') }}
          </p>

          <p style="max-width:250px" v-if="currentPatient.notes">
            {{ $t('Notas') }}: {{ currentPatient.notes }}
          </p>

          <!-- <p>Total de pedidos: 0</p> -->
          <p></p>
          <!-- <a style="font-weight: bold;" @click="selectCurrentConsult(consult)">
            Ver historial de pedidos
          </a> -->

          <!-- <p>
            <a
              style="font-weight: bold;"
              @click="selectCurrentConsult(consult)"
            >
              Envía un SMS
            </a>
          </p> -->
          <div v-for="(x, index) in currentPatient.documents" :key="x.url">
            <a :href="x.url" target="_blank"
              >{{ $t('Documento') }} {{ index + 1 }}</a
            >
          
          </div>
          <div v-for="rol in currentPatient.memcard_roles" :key="rol">
            {{ rol }}
          </div>
          <p>{{ $t('Socios Avalistas') }}:</p>
          <div v-for="enm in currentPatient.endorsement_member" :key="enm.nid">
            {{ enm.name }} ({{ enm.number }})
          </div>

          <img
            v-if="currentPatient.signature"
            class="imgRigth"
            style="height: 80; width: 40; object-fit:cover;"
            :src="currentPatient.signature.url"
          />
        </div>

        <div class="menu right ml-4">
          <ul>
            <li
              @click="walletRecharge()"
              v-if="
                (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                  this.currentClub.functionalities.monedero) &&
                  walletPermission
              "
            >
              <div>
                <h6>{{ $t('Recarga monedero') }}</h6>
              </div>
            </li>
            <!-- <li @click="feeManager()">
              <div>
                <h6>Renovar cuota</h6>
              </div>
            </li> -->
            <li
              @click="showPOS()"
              v-if="this.checkRoles(['Administrador', 'Manager', 'Budtender'])"
            >
              <h6>{{ $t('Hacer pedido') }}</h6>
            </li>
            <li @click="showOrdersReport()">
              <h6>{{ $t('Ver informe de pedidos') }}</h6>
            </li>
            <li
              @click="showWalletReport()"
              v-if="
                (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                  this.currentClub.functionalities.monedero) &&
                  walletPermission
              "
            >
              <h6>{{ $t('Ver informe de monedero') }}</h6>
            </li>
            <li @click="sendPushToPatient()">
              <h6>{{ $t('Enviar notificación push') }}</h6>
            </li>
            <li @click="sendSMSCode()">
              <div>
                <h6>{{ $t('Reenviar código de invitación') }}</h6>
              </div>
            </li>
            <hr
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            />
            <span
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            >
              Control de acceso
            </span>
            <li
              @click="checkin()"
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            >
              <div>
                <h6>{{ $t('ENTRA') }}</h6>
              </div>
            </li>
            <li
              @click="checkout()"
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            >
              <div>
                <h6>{{ $t('SALE') }}</h6>
              </div>
            </li>
            <hr />
            <li
              @click="patchPatient()"
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            >
              <div>
                <h6>{{ $t('Modificar socio') }}</h6>
              </div>
            </li>
            <li
              @click="restartPassword(currentPatient.email)"
              v-if="
                currentPatient.is_staff == true &&
                  currentPatient.email &&
                  this.checkRoles(['Administrador', 'Manager'])
              "
            >
              <div>
                <h6>{{ $t('Restablecer contraseña') }}</h6>
              </div>
            </li>
            <li
              @click="setPatientStatus(!currentPatient.status)"
              v-if="this.checkRoles(['Administrador', 'Manager', 'Recepción'])"
            >
              <div v-if="currentPatient && currentPatient.status == true">
                <h6>{{ $t('Desactivar socio') }}</h6>
              </div>
              <div v-else>
                <h6>{{ $t('Activar socio') }}</h6>
              </div>
            </li>
            <!-- <li
              style="background-color: #ed6a5a; color:#fff"
              @click="deletePatient()"
              v-if="this.checkRoles(['Administrador', 'Manager'])"
            >
              <div>
                <h6>{{ $t('Eliminar socio del club') }}</h6>
              </div>
            </li> -->
          </ul>
        </div>
      </section>
      <footer class="modal-card-foot">
        <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      </footer>
    </div>
  </div>
</template>

<script>
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import APINotifications from '@/services/APINotifications.js'

import WalletRecharge from '@/components/WalletRecharge.vue'
import FeeManager from '@/components/FeeManager.vue'
import axios from 'axios'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { mapState } from 'vuex'
export default {
  props: {
    currentPatient: Object
    // currentClub: Object
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    translateType(type) {
      if (type === 'ludico') {
        return this.$t('Lúdico')
      } else if (type === 'terapeutico') {
        return this.$t('Terapéutico')
      }
    },
    checkin() {
      this.$store.dispatch('accesscontrol/addNewAccess', {
        patient_id: this.currentPatient.nid,
        association: this.currentClub.nid,
        datetime: Date.parse(new Date()) / 1000,
        action: 'check-in'
      })
      this.$buefy.toast.open({
        duration: 3000,
        message: `Checkin correcto`,
        position: 'is-top',
        type: 'is-success'
      })
    },
    checkout() {
      this.$store.dispatch('accesscontrol/addNewAccess', {
        patient_id: this.currentPatient.nid,
        association: this.currentClub.nid,
        datetime: Date.parse(new Date()) / 1000,
        action: 'check-out'
      })
      this.$buefy.toast.open({
        duration: 3000,
        message: `Checkout correcto`,
        position: 'is-top',
        type: 'is-success'
      })
    },
    restartPassword(mail) {
      axios
        .post('https://backend.smokapp.com/api/user/request_new_password', {
          name: mail
        })
        .then(response => {
          this.$buefy.toast.open({
            duration: 8000,
            message: `Se ha enviado un email con un enlace para recuperar la contraseña`,
            position: 'is-top',
            type: 'is-danger'
          })
          console.log(response.data)
          console.log(response.status)
          console.log(response.statusText)
          console.log(response.headers)
          console.log(response.config)
        })
    },
    showOrdersReport() {
      this.$emit('close')
      this.$router.push({
        name: 'ordersReport',
        query: { ms_id: this.currentPatient.nid }
      })
    },
    showPOS() {
      this.$emit('close')
      this.$router.push({
        name: 'pos',
        params: { patient: this.currentPatient }
      })
    },
    showWalletReport() {
      this.$emit('close')
      this.$router.push({
        name: 'walletReport',
        query: { ms_id: this.currentPatient.nid }
      })
    },
    setPatientStatus(status) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        nid: this.currentPatient.nid,
        membership_id: this.currentPatient.nid,
        status_member: status,
        number: this.currentPatient.number
      }
      this.$store.dispatch('patients/editPatient', json).then(() => {
        loadingComponent.close()
        this.fetchPatients(loadingComponent)
        this.$emit('close')
      })
    },
    feeManager() {
      this.$buefy.modal.open({
        parent: this,
        component: FeeManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentPatient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    walletRecharge() {
      this.$buefy.modal.open({
        parent: this,
        component: WalletRecharge,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentPatient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    sendPushToPatient() {
      if (this.currentPatient.user && this.currentPatient.user) {
        this.$buefy.dialog.prompt({
          title: this.$t('Enviar mensaje push'),
          message: this.$t('Escribe el mensaje a enviar'),
          inputAttrs: {
            maxlength: 100
          },
          confirmText: 'Enviar',
          cancelText: 'Cancelar',
          trapFocus: true,
          onConfirm: value => {
            const loadingComponent = this.$buefy.loading.open({
              container: null
            })
            APINotifications.sendPushNotification(this.currentClub.nid, value, [
              this.currentPatient.user.uid
            ])
              .then(response => {
                loadingComponent.close()
                this.$buefy.notification.open({
                  message: this.$t('Notificación enviada correctamente'),
                  position: 'is-top',
                  type: 'is-success',
                  hasIcon: false,
                  duration: 5000
                })
                this.$matomo.trackEvent('PatientManager', 'Click', 'sendPush', 1)
              })
              .catch(error => {
                console.log('Ha habido un error:' + error)
                console.log(error.response)
                loadingComponent.close()
                tools.manageAxiosError(error)

                // this.$buefy.notification.open({
                //   message: error,
                //   position: 'is-top-right',
                //   type: 'is-danger',
                //   hasIcon: false,
                //   duration: 5000
                // })
              })
          }
        })
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('Cuenta online'),
          message: this.$t('Este socio no dispone de cuenta online'),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false
        })
      }
    },
    sendSMSCode() {
      if (this.currentPatient.user && this.currentPatient.telefono) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.$store
          .dispatch('patients/sendSMSCode', {
            asocid: this.currentClub.nid,
            uid: this.currentPatient.user.uid,
            tlf: this.currentPatient.telefono,
            country_code: this.currentPatient.country_code
          })
          .then(() => {
            this.$buefy.notification.open({
              message: this.$t('SMS de invitación enviado correctamente'),
              position: 'is-top-right',
              type: 'is-success',
              hasIcon: false
            })
            loadingComponent.close()
          })
      } else {
        this.$buefy.dialog.confirm({
          title: 'Cuenta online',
          message:
            this.$t('Este socio no dispone de cuenta online, ¿Quieres activarla?'),
          confirmText: 'Sí',
          cancelText: 'No',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            if (this.currentPatient.telefono) {
              const loadingComponent = this.$buefy.loading.open({
                container: null
              })
              let json = {
                association: this.currentClub.nid,
                nid: this.currentPatient.nid,
                membership_id: this.currentPatient.nid,
                online_account: true,
                number: this.currentPatient.number
              }
              this.$store.dispatch('patients/editPatient', json).then(() => {
                // loadingComponent.close()
                this.fetchPatients(loadingComponent)
              })
            } else {
              this.$buefy.dialog.prompt({
                title: this.$t('Nº de teléfono'),
                message:
                  this.$t('El usuario no dispone e nº de teléfono, ¿A qué número de teléfono le enviamos la invitación?'),
                inputAttrs: {
                  maxlength: 16
                },
                confirmText: 'Enviar',
                cancelText: 'Cancelar',
                trapFocus: true,
                onConfirm: value => {
                  const loadingComponent = this.$buefy.loading.open({
                    container: null
                  })
                  let json = {
                    association: this.currentClub.nid,
                    nid: this.currentPatient.nid,
                    membership_id: this.currentPatient.nid,
                    online_account: true,
                    telefono: value,
                    number: this.currentPatient.number
                  }
                  this.$store
                    .dispatch('patients/editPatient', json)
                    .then(() => {
                      // loadingComponent.close()
                      this.fetchPatients(loadingComponent)
                    })
                }
              })
            }
          }
        })
      }
    },
    deletePatient() {
      if (this.currentPatient == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('Primero debes seleccionar un socio'),
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Eliiminar socio',
        message:
          this.$t('¿Estás seguro de que deseas eliminar al socio? (Esta acción no se puede deshacer)'),
        confirmText: this.$t('Sí'),
        cancelText: this.$t('No'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          this.$store
            .dispatch('patients/deletePatient', {
              asocid: this.currentClub.nid,
              patient: this.currentPatient
            })
            .then(response => {
              // this.selectFirstUser()
              this.$emit('reloadPatients')
              this.$emit('close')
              loadingComponent.close()
            })
            .catch(error => {
              tools.manageAxiosError(error)
              console.log('Ha habido un error:' + error)
              loadingComponent.close()
            })
        }
      })
    },
    patchPatient() {
      this.$emit('close')
      this.$router.push({
        name: 'patientcreate',
        params: { patientToPatch: this.currentPatient }
      })
    },
    fetchPatients(loadingComponent) {
      this.$emit('reloadPatients')
      loadingComponent.close()
      // this.$store
      //   .dispatch('patients/fetchPatients', {
      //     asocid: this.currentClub.nid,
      //     is_staff: null
      //   })
      //   .then(() => {
      //     loadingComponent.close()
      //   })
    }
  },
  created() {
    this.$analytics.logEvent('PatientDetail.vue')
  }
}
</script>

<style lang="scss" scoped>
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}
</style>
