import session from '@/services/Session.js'

export default {
  postOrder(order) {
    console.log('order APIOrder')
    console.log(order)
    return session.post('/api/order', order)
  },
  deleteOrder(order) {
    return session.delete(
      '/api/order/delete?asocid=' + order.asocid + '&oid=' + order.oid
    )
  },
  completeOrder(order) {
    return session.post('/api/order/complete', order)
  },
  refundOrder(order) {
    return session.post('/api/order_refund', order)
  },
  payOrder(payment) {
    return session.post('/api/order/pay', payment)
  },
  listClubOrders(asocid, start_date, end_date, membership_id, limit, page) {
    var url = '/api/order/?asocid=' + asocid
    if (start_date && end_date) {
      url = url + '&start_date=' + start_date + '&end_date=' + end_date
    }
    if (membership_id) {
      url = url + '&membership_id=' + membership_id
    }
    if (limit) {
      url = url + '&limit=' + limit
    }
    if (page) {
      url = url + '&page=' + page
    }
    return session.get(url)
  },
  listClubOrdersInProcess(asocid) {
    return session.get('/api/order/?asocid=' + asocid + '&status=cart')
  },
  listClubOrdersCompleted(asocid, start_date, end_date) {
    return session.get(
      '/api/order/?asocid=' +
        asocid +
        '&status=completed' +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date
      // + '&limit=3&page=1'
    )
  },
  listOrders(asocid, fichaid) {
    console.log('entra en api/listOrders')
    console.log(asocid)
    console.log(fichaid)
    return session.get(
      '/api/order?asocid=' + asocid + '&membership_id=' + fichaid
    )
  },
  listOrdersStatus(asocid, fichaid, status) {
    return session.get(
      '/api/order/?asocid=' +
        asocid +
        '&membership_id=' +
        fichaid +
        '&status=' +
        status
    )
  }
  // listOrders(asocid, fichaid, status, oid) {
  //   return session.get(
  //     '/api/order/?asocid=' +
  //       asocid +
  //       '&fichaid=' +
  //       fichaid +
  //       '&status=' +
  //       status +
  //       '&oid=' +
  //       oid
  //   )
  // }
}
