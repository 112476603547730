import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth.js'
import orders from '@/store/orders.js'
import dispensaryBasic from '@/store/dispensarybasic.js'
import dispensaryPro from '@/store/dispensarypro.js'
import club from '@/store/club.js'
import users from '@/store/users.js'
import tvshow from '@/store/tvshow.js'
import consults from '@/store/consults.js'
import notifications from '@/store/notifications.js'
import news from '@/store/news.js'
import events from '@/store/events.js'
import patients from '@/store/patients.js'
import inventory from '@/store/inventory.js'
import accesscontrol from '@/store/accesscontrol.js'
import register from '@/store/register.js'

import {
  SET_SHOW_NAVIGATION_BAR,
  SET_QRVALUE,
  SET_MICROBLINK_DATA,
  SET_SIDE_MENU_IS_ACTIVE
} from './types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    updateAvailable: false,
    name: 'Manue',
    xcsrfToken: String,
    cookie: String,
    showNavigationBar: true,
    sideMenuIsActive: true,
    qrValue: '',
    microblink_data: {
      name: String,
      surname: String,
      dni: String,
      birthdate: Date
    }
  },
  getters: {},
  mutations: {
    ['SET_UPDATE_AVAILABLE'](state, updateAvailable) {
      state.updateAvailable = updateAvailable
    },
    [SET_SIDE_MENU_IS_ACTIVE](state, sideMenuIsActive) {
      state.sideMenuIsActive = sideMenuIsActive
    },
    [SET_SHOW_NAVIGATION_BAR](state, needsToShow) {
      state.showNavigationBar = needsToShow
    },
    [SET_QRVALUE](state, qrValue) {
      state.qrValue = qrValue
    },
    [SET_MICROBLINK_DATA](state, microblink_data) {
      state.microblink_data = microblink_data
    }
  },
  actions: {
    setShowNavigationBar({ commit }, needsToShow) {
      commit(SET_SHOW_NAVIGATION_BAR, needsToShow)
    },
    setQRValue({ commit }, qrValue) {
      commit(SET_QRVALUE, qrValue)
    },
    setMicroblinkData({ commit }, name, surname, dni, birthdate) {
      commit(SET_MICROBLINK_DATA, name, surname, dni, birthdate)
    }
  },
  modules: {
    auth,
    orders,
    dispensaryBasic,
    dispensaryPro,
    club,
    users,
    tvshow,
    consults,
    notifications,
    news,
    events,
    patients,
    inventory,
    accesscontrol,
    register
  }
})
