<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('HISTORIAL DE CIERRES DE CAJA') }}
    </h2>
    <b-table
      v-if="closeRegisterHistory"
      style="text-align: left;"
      :data="this.closeRegisterHistory.cash_close"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="created"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :selected.sync="selected"
      hoverable
      striped
      height="calc(100vh - 250px)"
    >
      <b-table-column
        field="cid"
        label="ID"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.cid }}
      </b-table-column>

      <b-table-column
        field="created"
        :label="$t('Fecha')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ format_date(props.row.created, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        field="current_amount"
        :label="$t('Cantidad')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.current_amount }}
      </b-table-column>

      <b-table-column
        field="employee_name"
        :label="$t('Trabajador')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.employee_name }}
      </b-table-column>

      <b-table-column
        field="note"
        :label="$t('Nota')"
        v-slot="props"
        width="50"
      >
        {{ props.row.note }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 19,
      selected: null,

      is_editing: false,
      note: null,
      currentAmount: null
    }
  },
  mixins: [ToolsMixin, InitViewMixin],

  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('register', ['closeRegisterHistory'])
  },
  methods: {},
  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager', 'Caja', 'Budtender'])
    this.$store.dispatch('register/fetechCloseRegisterhistory', {
      asocid: this.currentClub.nid
    })
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Caja', 'Budtender'])
    }
  }
}
</script>

<style lang="scss" scoped></style>
