<template>
  <div class="colorSelector">
    <!-- <i class="fas fa-images fa-4x"></i> -->
    <h2 class="mt-3 mb-3">{{ $t('Carrusel de productos') }}</h2>
    <div class="field">
      <b-switch v-model="showItemSubtitle">
        <span v-if="showItemSubtitle"
          >{{ $t('Mostrar subtítulo del producto') }}
        </span>
        <span v-else> {{ $t('No mostrar subtítulo del producto') }} </span>
      </b-switch>
    </div>
    <div class="field">
      <b-switch v-model="showItemDescription">
        <span v-if="showItemDescription"
          >{{ $t('Mostrar descripción del producto') }}
        </span>
        <span v-else> {{ $t('No mostrar descripción del producto') }} </span>
      </b-switch>
    </div>
    <!-- <div class="field">
      <b-switch v-model="showItemQr">
        <span v-if="showItemQr">Mostrar QR del producto </span>
        <span v-else> No mostrar QR del producto </span>
      </b-switch>
    </div> -->
    <b-field :label="$t('Intervalo en milisegundos')">
      <b-input
        class="ml-3"
        style="width:50%;"
        v-model="interval"
        maxlength="6"
        :placeholder="$t('Intervalo en milisegundos')"
      ></b-input>
    </b-field>
    <hr />
    <!-- <i class="fas fa-sliders-h fa-4x"></i> -->
    <h2 class="mb-3">{{ $t('Opciones genéricas') }}</h2>
    <div class="form__field">
      <div class="form__label">
        <strong>{{ $t('Color de fondo') }}</strong>
      </div>
      <div class="form__input">
        <v-swatches
          v-model="bkColor"
          popover-x="left"
          row-length="6"
          show-fallback
          :show-border="true"
          fallback-input-type="color"
          :swatches="swatches"
        ></v-swatches>
      </div>
    </div>
    <div class="form__field">
      <div class="form__label">
        <strong>{{ $t('Color del texto') }}</strong>
      </div>
      <div class="form__input">
        <v-swatches
          v-model="txtColor"
          popover-x="left"
          row-length="6"
          show-fallback
          :show-border="true"
          fallback-input-type="color"
          :swatches="swatches"
        ></v-swatches>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      bkColor: '#A463BF',
      txtColor: '#FFFFFF',
      interval: 10000,
      showItemDescription: true,
      showItemSubtitle: true,
      showItemQr: true,
      swatches: [
        ['#9BD0EB', '#A2F5A2', '#DED19E', '#F5B1A2', '#CF9DF2'],
        ['#FFFFFF', '#000000', '#DF4F54', '#3F6CD1', '#FFEB54'],
        ['#F244C4', '#A904BF', '#C743D9', '#F2F2F2', '#0D0D0D'],
        ['#3B9194', '#E087C3', '#70DCE0', '#E0CC5A', '#948843'],
        ['#4573AD', '#0C3D7A', '#70DCE0', '#E4B9AB', '#AD4F45']
      ]
    }
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState('dispensaryBasic', ['dispensary']),
    ...mapState('tvshow', ['carouselSettings'])
  },
  components: {
    VSwatches
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.fetchDispensary()
    },

    bkColor() {
      console.log('entra')
      let settings = this.carouselSettings
      settings.backgroundcolor = this.bkColor
      console.log(settings)
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    },
    txtColor() {
      let settings = this.carouselSettings
      settings.textcolor = this.txtColor
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    },
    interval() {
      let settings = this.carouselSettings
      settings.interval = this.interval
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    },
    showItemDescription() {
      let settings = this.carouselSettings
      settings.showItemDescription = this.showItemDescription
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    },
    showItemSubtitle() {
      let settings = this.carouselSettings
      settings.showItemSubtitle = this.showItemSubtitle
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    },
    showItemQr() {
      let settings = this.carouselSettings
      settings.showItemQr = this.showItemQr
      this.$store.dispatch('tvshow/setCarouselSettings', settings)
    }
  },
  mounted() {
    this.bkColor = this.carouselSettings.backgroundcolor
    this.txtColor = this.carouselSettings.textcolor
    this.interval = this.carouselSettings.interval
    this.showItemSubtitle = this.carouselSettings.showItemSubtitle
    this.showItemDescription = this.carouselSettings.showItemDescription
    this.showItemQr = this.carouselSettings.showItemQr
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.$analytics.logEvent('TVShowSettings.vue')

    this.$store.dispatch('tvshow/initialize')
    this.$store.dispatch('setShowNavigationBar', true)
    // this.$store.dispatch('auth/initialize')
  }
}
</script>

<style lang="scss" scoped>
.colorSelector {
  text-align: left;
  // position: absolute;
  // left: 50%;
  // // top: 50%;
  // transform: translate(-50%, 10%);
}
</style>
