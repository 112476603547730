<template>
  <section>
    <b-steps
      v-model="activeStep"
      :animated="isAnimated"
      :rounded="isRounded"
      :has-navigation="hasNavigation"
      :icon-prev="prevIcon"
      :icon-next="nextIcon"
      :label-position="labelPosition"
      :mobile-mode="mobileMode"
      class="mt-4 mb-6"
    >
      <b-step-item
        class="content-slim"
        step="1"
        :label="$t('Datos personales')"
        :clickable="isStepsClickable"
        icon="user-lock"
        :type="{ 'is-success': isProfileSuccess }"
      >
        <h1 class="title has-text-centered">{{ $t('Datos personales') }}</h1>
        <section>
          <div>
            <img
              v-if="photo"
              :src="photo"
              style="width:250px; height:250px;   border-radius:50%; object-fit:cover;"
              @click="openTakeAPhoto()"
            />
          </div>
          <div class="content">
            <div style="display:flex">
              <div style="width:100%">
                <b-field
                  :label="$t('Nombre y apellidos')"
                  :type="nametypealert"
                  :message="namemessagealert"
                >
                  <b-input v-model="name" :placeholder="$t('Nombre')"></b-input>
                  <b-input
                    v-model="surname"
                    :placeholder="$t('Primer apellido')"
                  ></b-input>
                  <b-input
                    v-model="surname2"
                    :placeholder="$t('Segundo apellido')"
                  ></b-input>
                  <b-button
                    icon-left="camera"
                    @click="openTakeAPhoto()"
                  ></b-button>
                </b-field>
                <!-- <b-field
                label="Apellidos"
                :type="surnametypealert"
                :message="surnamemessagealert"
              >
              </b-field> -->
                <!-- <b-field label="Segundo Apellido">
                <b-input
                  v-model="surname2"
                  placeholder="Introduce aquí el segundo apellido"
                ></b-input>
              </b-field> -->
              </div>
            </div>

            <b-field
              :label="$t('DNI y Fecha de nacimiento')"
              :type="dnitypealert"
              :message="dnimessagealert"
            >
              <b-input v-model="dni" :placeholder="$t('DNI')"></b-input>
              <b-datepicker
                v-model="birthdate"
                :show-week-number="false"
                :locale="this.$i18n.locale"
                :placeholder="$t('Fecha de nacimiento')"
                :first-day-of-week="1"
                trap-focus
              >
              </b-datepicker>
            </b-field>

            <b-field :label="$t('Teléfono')">
              <b-input
                style="width:60px"
                v-model="country_code"
                placeholder="+34"
              ></b-input>
              <b-input v-model="tlf" :placeholder="$t('Teléfono')"></b-input>
              <b-input
                v-model="email"
                type="email"
                maxlength="60"
                :disabled="this.patientToPatch && this.patientToPatch.email"
                :placeholder="$t('Email')"
              >
              </b-input>
            </b-field>
            <h1 class="title has-text-centered">{{ $t('Dirección') }}</h1>
            <b-field :label="$t('Calle')">
              <b-input
                v-model="memcard_address.thoroughfare"
                :placeholder="$t('Calle')"
              ></b-input>
            </b-field>
            <b-field :label="$t('Ciudad')">
              <b-input
                v-model="memcard_address.locality"
                :placeholder="$t('Ciudad')"
              ></b-input>
              <b-input
                v-model="memcard_address.postal_code"
                :placeholder="$t('Código postal')"
              ></b-input>

              <b-select v-model="memcard_address.country" :value="country">
                <option value="ES">{{ $t('España') }}</option>
                <option value="IT">{{ $t('Italia') }}</option>
                <option value="FR">{{ $t('Francia') }}</option>
                <option value="AL">{{ $t('Alemania') }}</option>
                <option value="US">{{ $t('EEUU') }}</option>
                <option value="CL">{{ $t('Chile') }}</option>
                <option value="CR">{{ $t('Costa Rica') }}</option>
                <option value="UY">{{ $t('Uruguay') }}</option>
              </b-select>
            </b-field>
          </div>
        </section>
      </b-step-item>

      <b-step-item
        class="content-slim content"
        step="2"
        :visible="true"
        :label="$t('Datos membresía')"
        :clickable="isStepsClickable"
        icon="users"
        :type="{ 'is-success': isProfileSuccess }"
      >
        <h1 class="title has-text-centered">{{ $t('Membresía') }}</h1>

        <div
          v-if="showQRReader"
          style="display: flex; align-items: center;justify-content: center;"
        >
          <QRReader @qrResult="qrResult"> </QRReader>
        </div>

        <b-field :label="$t('Tipo de socio')">
          <b-select v-model="type_member" :value="type_member">
            <option value="ludico">{{ $t('Lúdico') }}</option>
            <option value="terapeutico">{{ $t('Terapéutico') }}</option>
          </b-select>
          <b-select v-model="category_member" :placeholder="$t('Categoría')">
            <option
              v-for="category in categories"
              :key="category.nid"
              :value="category.nid"
            >
              {{ category.title }}
            </option>
          </b-select>
        </b-field>

        <b-field :label="$t('Nº de socio')">
          <b-input v-model="number" :placeholder="$t('Nº de socio')"></b-input>
          <b-input
            v-model="keychain"
            :placeholder="$t('LLavero / Tarjeta')"
          ></b-input>
          <b-button
            v-if="this.currentClub.functionalities.gestion_avanzada_socios"
            @click="qrtoggle()"
            style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
            ><i class="fas fa-qrcode fa"></i>
          </b-button>
          <!-- <div v-if="checkNFC()">
            <b-button @click="readNFC()">
              <b-icon icon="wifi"> </b-icon>
            </b-button>
            <b-button @click="writeNFC()"
              >Enviar              
            </b-button>
          </div> -->

          <!-- <b-button @click="writeNFC()">Write </b-button> -->
        </b-field>
        <b-field :label="$t('Previsión de consumo (grs/mes)')">
          <b-input
            type="number"
            v-model="consum_forecast"
            :placeholder="$t('Previsión de consumo')"
          ></b-input>
        </b-field>
        <b-field
          :label="$t('Fecha fin de cuota')"
          v-if="this.checkRoles(['Administrador'])"
        >
          <b-datepicker
            v-model="end_date"
            :show-week-number="false"
            :locale="this.$i18n.locale"
            :placeholder="$t('Fecha fin de cuota')"
            trap-focus
            :first-day-of-week="1"
          >
          </b-datepicker>
        </b-field>
        <b-field
          :label="$t('Monedero negativo')"
          v-if="
            (this.currentClub.functionalities.funciones_avanzadas_gestion ||
              this.currentClub.functionalities.monedero) &&
              walletPermission
          "
        >
          <b-switch v-model="negative_wallet">
            {{ $t('Permitir monedero negativo') }}
          </b-switch>
        </b-field>
        <b-field :label="$t('Cuenta de usuario')">
          <b-switch
            v-model="online_account"
            :disabled="!online_account_enabled || is_staff"
          >
            {{ $t('Crear cuenta de usuario para la app') }}
          </b-switch>
        </b-field>

        <b-field
          :label="$t('Trabajadores')"
          v-if="this.checkRoles(['Administrador'])"
        >
          <!-- <b-switch v-model="is_staff" :disabled="this.patientToPatch">
            {{ $t('Es miembro del staff del club') }}
          </b-switch> -->
          <b-switch v-model="is_staff" :disabled="this.patientToPatch && this.patientToPatch.is_staff">
            {{ $t('Es miembro del staff del club') }}
          </b-switch>
          <div class="mt-3" v-if="is_staff">
            <span>{{ $t('Roles del trabajador') }}</span>
            <b-field class="mt-1">
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Social">{{ $t('Social') }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Recepción">{{ $t('Recepción') }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Budtender">{{ $t('Budtender') }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Inventario">{{
                $t('Inventario')
              }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Caja">{{ $t('Caja') }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Manager">{{ $t('Manager') }}</b-checkbox>
              <b-checkbox :disabled="this.patientToPatch && this.patientToPatch.is_staff" v-model="Administrador">
                {{ $t('Administrador') }}</b-checkbox
              >
            </b-field>

            <!-- <b-select v-model="member_roles" :value="member_roles" multiple>
              <option value="Social">Social</option>
              <option value="Recepción">Recepción</option>
              <option value="Budtender">Budtender</option>
              <option value="Manager">Manager</option>
              <option value="Administrador">Administrador</option>
            </b-select> -->
          </div>
        </b-field>
        <b-field :label="$t('Socios Avalistas')">
          <b-button @click="showSelectUsers()" style="width:100%;">
            <div v-if="this.selectedPatients">
              <div
                v-for="patient in this.selectedPatients"
                :key="patient.nid"
                :value="patient"
                style="display:inline"
              >
                <span> · {{ patient.nombre }}</span>
              </div>
            </div>
            <!-- <div v-else>
              <div
                v-for="patient in this.endorsement_member"
                :key="patient.nid"
                :value="patient"
                style="display:inline"
              >
                <span> · {{ patient.name }}</span>
              </div>
            </div> -->
          </b-button>
        </b-field>
        <b-input
          v-model="notes"
          maxlength="200"
          type="textarea"
          :placeholder="$t('Notas')"
        ></b-input>

        <b-field :label="$t('Documentos')">
          <b-upload v-model="dropFiles" multiple drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>
                  {{
                    $t('Arrastra aquí los documentos o pulsa para selccionar')
                  }}
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
          <span
            v-for="(x, index) in documents"
            :key="x.url"
            class="tag is-primary"
          >
            <a :href="x.url" target="_blank"
              >{{ $t('Documento') }} {{ index + 1 }}</a
            >
            <button
              class="delete is-small"
              type="button"
              @click="deleteDocumentFile(index)"
            ></button>
          </span>
        </div>

        <div class="tags">
          <span
            v-for="(file, index) in dropFiles"
            :key="index"
            class="tag is-primary"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile(index)"
            ></button>
          </span>
        </div>
      </b-step-item>

      <b-step-item
        class="content"
        step="3"
        :label="$t('Contrato y firma')"
        :clickable="isStepsClickable"
        disabled
        icon="signature"
        :type="{ 'is-success': isProfileSuccess }"
      >
        <patient-agreement
          :name="name"
          :surname="surname"
          :surname2="surname2"
          :dni="dni"
          :memcard_address="memcard_address"
          :type_member="type_member"
          :signature="signature"
          @recieveSignature="recieveSignature"
          class="mt-2"
        ></patient-agreement>

        <b-button @click="sendData()"> {{ $t('Terminar') }}</b-button>
      </b-step-item>

      <template v-if="customNavigation" #navigation="{ previous, next }">
        <b-button
          outlined
          type="is-danger"
          icon-pack="fas"
          icon-left="backward"
          :disabled="previous.disabled"
          @click.prevent="previous.action"
        >
          {{ $t('Anterior') }}
        </b-button>
        <b-button
          outlined
          type="is-success"
          icon-pack="fas"
          icon-right="forward"
          :disabled="next.disabled"
          @click.prevent="next.action"
        >
          {{ $t('Siguiente') }}
        </b-button>
      </template>
    </b-steps>
  </section>
</template>

<script>
import TakeAPhoto from '@/views/Utils/TakeAPhoto.vue'
import PatientsList from '@/components/PatientsList.vue'
import { mapState } from 'vuex'
import PatientAgreement from '@/components/PatientAgreement.vue'
import tools from '@/Tools/tools.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import APIPatients from '@/services/APIPatients.js'
import axios from 'axios'
import QRReader from '@/components/QRReader.vue'

export default {
  components: { PatientAgreement, QRReader },
  mixins: [InitViewMixin],
  data() {
    return {
      dropFiles: [],
      createdFids: [],
      documents: [],
      locale: 'es-ES',
      country: 'ES',
      activeStep: 0,
      dnitypealert: '',
      dnimessagealert: '',
      nametypealert: '',
      namemessagealert: '',
      surnametypealert: '',
      surnamemessagealert: '',
      signature: null,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: true,

      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      online_account: false,
      is_staff: false,
      Administrador: false,
      Manager: false,
      Budtender: false,
      Recepción: false,
      Social: false,
      Inventario: false,
      Caja: false,
      selectedPatients: [],
      name: '',
      surname: '',
      surname2: '',
      dni: '',
      birthdate: null,
      category_member: null,
      tlf: '',
      country_code: '',
      email: '',
      notes: '',
      number: '',
      type_member: 'ludico',
      consum_forecast: '',
      endorsement_member: [],
      endorsement_member_string: '',
      keychain: '',
      photo: null,
      memcard_contract: '',
      end_date: null,
      status_member: true,
      memcard_address: {
        thoroughfare: '',
        // administrative_area: 'M',
        country: 'ES',
        locality: '',
        postal_code: ''
      },
      signatureString: null,
      online_account_enabled: true,
      negative_wallet: false,
      showQRReader: false
    }
  },
  props: {
    patientToPatch: Object
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['patients', 'categories']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    qrtoggle() {
      this.$matomo.trackEvent('PatientCreate', 'Click', 'qrReader', 1)
      this.showQRReader = !this.showQRReader
    },
    qrResult(result) {
      this.keychain = result
      this.showQRReader = !this.showQRReader
    },
    checkNFC() {
      if ('NDEFReader' in window) {
        return true
      } else {
        return false
      }
    },
    readNFC() {
      this.$buefy.toast.open({
        duration: 4000,
        message: `Acerca la tarjeta o allavero`,
        position: 'is-top',
        type: 'is-success'
      })
      const ndef = new NDEFReader()
      return new Promise((resolve, reject) => {
        const ctlr = new AbortController()
        ctlr.signal.onabort = reject
        ndef.addEventListener(
          'reading',
          event => {
            this.keychain = event.serialNumber
            ctlr.abort()
            // resolve(event)
          },
          { once: true }
        )
        ndef.scan({ signal: ctlr.signal }).catch(err => reject(err))
      })
    },
    writeNFC() {
      const ndef = new NDEFReader()

      ndef
        .write(this.keychain)
        .then(() => {
          console.log('Message written.')
          this.$buefy.toast.open({
            duration: 4000,
            message: `NFC enviado correctamente`,
            position: 'is-top',
            type: 'is-success'
          })
        })
        .catch(error => {
          console.log(`Write failed :-( try again: ${error}.`)
        })
    },
    // readNFC() {
    //   // this.read().then(({ serialNumber }) => {
    //   //   console.log(serialNumber)
    //   //   window.alert(serialNumber)
    //   // })

    //   const ndef = new NDEFReader()
    //   // Prompt user to allow website to interact with NFC devices.
    //   ndef.scan()
    //   ndef.onreading = event => { //Lee sin parar
    //     // TODO: Handle incoming NDEF messages.
    //     console.log(event)
    //     // window.alert(event.serialNumber)
    //     this.keychain = event.serialNumber
    //   }
    // },

    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    deleteDocumentFile(index) {
      this.documents.splice(index, 1)
    },
    recieveSignature(value) {
      this.signatureString = value
    },
    validateData() {
      // if (!this.patientToPatch && this.signatureString == null) {
      //   this.$buefy.toast.open({
      //     duration: 5000,
      //     message: `Recuerda que falta la firma del socio`,
      //     position: 'is-bottom',
      //     type: 'is-danger'
      //   })
      //   return false
      // }
      if (this.name == '') {
        this.activeStep = 0
        this.nametypealert = 'is-danger'
        this.namemessagealert = this.$t('El nombre es obligatorio')
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t("El campo 'nombre' es obligatorio"),
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      } else {
        this.nametypealert = ''
        this.namemessagealert = ''
      }
      if (this.surname == '') {
        this.activeStep = 0
        this.surnametypealert = 'is-danger'
        this.surnamemessagealert = this.$t('El apellido es obligatorio')
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t(`El campo 'apellido' es obligatorio`),
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      } else {
        this.surnametypealert = ''
        this.surnamemessagealert = ''
      }
      if (this.dni == '') {
        this.activeStep = 0
        this.dnitypealert = 'is-danger'
        this.dnimessagealert = this.$t('El DNI es obligatorio')
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('El campo DNI es obligatorio'),
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      } else {
        this.dnitypealert = ''
        this.dnimessagealert = ''
      }

      return true
    },

    sendData() {
      if (this.validateData() == false) {
        return
      }

      //  // PACO ESTO FALLA SI LE PASO FICHEROS MULTIPLES
      //   APIPatients.createFile(this.dropFiles[0], this.currentClub.nid)
      //     .then(response => {
      //       console.log(response)
      //       this.createdFids.push(response.data[0].fid)
      //     })
      //     .catch(error => {
      //       tools.manageAxiosError(error)
      //       console.log('Ha habido un error:' + error)
      //     })

      let json = {
        association: this.currentClub.nid,
        dni: this.dni,
        birthday: Date.parse(this.birthdate) / 1000,
        type_member: this.type_member,
        // memcard_contract: this.memcard_contract,
        end_date: Date.parse(this.end_date) / 1000,
        status_member: this.status_member,
        memcard_address: this.memcard_address,
        online_account: this.online_account,
        is_staff: this.is_staff,
        monedero_negativo: this.negative_wallet
      }
      if (this.generateEndorsementArray().length > 0) {
        json = Object.assign(
          { endorsement_member: this.generateEndorsementArray() },
          json
        )
      }
      if (this.photo !== null) {
        if (this.patientToPatch) {
          if (this.photo.substring(1, 4) != 'http') {
            json = Object.assign({ foto: this.photo.split(',')[1] }, json)
          }
        } else {
          json = Object.assign({ foto: this.photo.split(',')[1] }, json)
        }
      }
      if (this.signatureString !== null) {
        if (this.patientToPatch) {
          if (this.signatureString.substring(1, 4) != 'http') {
            json = Object.assign(
              { signature: this.signatureString.split(',')[1] },
              json
            )
          }
        } else {
          json = Object.assign(
            { signature: this.signatureString.split(',')[1] },
            json
          )
        }
      }
      json = Object.assign({ telefono: this.tlf }, json)
      if (this.country_code && this.country_code !== '') {
        json = Object.assign(
          { country_code: this.country_code.replace('+', '') },
          json
        )
      }
      if (this.name !== '') {
        json = Object.assign({ nombre: this.name }, json)
      }
      if (this.surname !== '') {
        json = Object.assign({ surname: this.surname }, json)
      }
      json = Object.assign({ surname2: this.surname2 }, json)
      json = Object.assign({ email: this.email }, json)
      if (this.number !== '') {
        json = Object.assign({ number: this.number }, json)
      }
      if (this.consum_forecast !== '') {
        json = Object.assign({ consum_forecast: this.consum_forecast }, json)
      } else {
        json = Object.assign({ consum_forecast: 60 }, json)
      }
      json = Object.assign({ notes: this.notes }, json)
      if (this.keychain !== '') {
        json = Object.assign({ keychain: this.keychain }, json)
      } else {
        json = Object.assign({ keychain: this.dni }, json)
      }
      if (this.patientToPatch) {
        json = Object.assign({ nid: this.patientToPatch.nid }, json)
      }
      if (this.category_member !== '') {
        json = Object.assign({ category_member: this.category_member }, json)
      }

      if (this.is_staff) {
        var memcard_roles = []

        if (this.Administrador !== false) {
          memcard_roles.push('Administrador')
        }

        if (this.Manager !== false) {
          memcard_roles.push('Manager')
        }

        if (this.Budtender !== false) {
          memcard_roles.push('Budtender')
        }

        if (this.Recepción !== false) {
          memcard_roles.push('Recepción')
        }

        if (this.Social !== false) {
          memcard_roles.push('Social')
        }

        if (this.Inventario !== false) {
          memcard_roles.push('inventario')
        }

        if (this.Caja !== false) {
          memcard_roles.push('Caja')
        }

        json.memcard_roles = memcard_roles
      } else {
        json.memcard_roles = []
      }

      var functions = []
      this.dropFiles.forEach(file => {
        functions.push(APIPatients.createFile(file, this.currentClub.nid))
      })

      axios
        .all(functions)
        .then(responseArr => {
          responseArr.forEach(response => {
            this.createdFids.push(response.data[0].fid)
          })

          // TODO: DESCOMENTAR PARA ENVIAR LOS DOCUMENTOS
          var documentFids = []
          if (this.documents) {
            this.documents.forEach(doc => {
              documentFids.push(doc.fid)
            })
          }
          const fidsTosend = [...documentFids, ...this.createdFids]
          // if (fidsTosend.length > 0) {
          json = Object.assign({ documents: fidsTosend }, json)
          // }

          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          if (this.patientToPatch) {
            json.membership_id = this.patientToPatch.nid

            //Editamos el socio
            this.$store
              .dispatch('patients/editPatient', json)
              .then(() => {
                loadingComponent.close()
                this.$store.dispatch('patients/clearPatients')
                this.$router.push({ name: 'patientmanager' })
              })
              .catch(error => {
                console.log('Ha habido un error:' + error.response.message)
                tools.manageAxiosError(error)
                loadingComponent.close()
              })
          } else {
            //Registramos un nuevo socio
            this.$store
              .dispatch('patients/createPatient', json)
              .then(() => {
                loadingComponent.close()
                this.$store.dispatch('patients/clearPatients')
                this.$router.push({
                  name: 'patientmanager',
                  params: {
                    propdni: this.dni
                  }
                })
              })
              .catch(error => {
                console.log('Ha habido un error:' + error)
                loadingComponent.close()
                tools.manageAxiosError(error)
              })
          }
        })
        .catch(error => {
          // The error is thrown here!
          console.log('Ha habido un error:' + error)
          tools.manageAxiosError(error)
        })
    },
    generateEndorsementArray() {
      console.log(this.selectedPatients)
      var tmparr = []
      this.selectedPatients.forEach(member => {
        tmparr.push(member.nid)
      })
      return tmparr
    },
    openTakeAPhoto() {
      this.$buefy.modal.open({
        parent: this,
        component: TakeAPhoto,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          item: this.dispensary_item
        },
        onCancel: function() {},
        events: {
          onCapture: value => {
            this.photo = value
          }
        }
      })
    },
    showSelectUsers() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientsList,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          is_multiple: true,
          filterActived: true,
          currentClub: this.currentClub
        },
        events: {
          seletedUsrs: value => {
            this.selectedPatients = value
          }
        }
      })
    }
  },
  created() {
    this.validateAccess(['socios', 'comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Recepción', 'Social'])
    this.$store.dispatch(
      'patients/fetchPatientCategories',
      this.currentClub.nid
    )
    this.$store
      .dispatch('club/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'consumptionforecast'
      })
      .then(response => {
        console.log(response)

        if (response.data.length != 0 && this.patientToPatch == null) {
          this.consum_forecast = response.data[0].title
        }
      })

    if (this.patientToPatch != null) {
      // if (this.patientToPatch.is_staff) {
      //   this.online_account_enabled = false
      // }
      this.Administrador = this.patientToPatch.memcard_roles.includes(
        'Administrador'
      )
      this.Manager = this.patientToPatch.memcard_roles.includes('Manager')
      this.Budtender = this.patientToPatch.memcard_roles.includes('Budtender')
      this.Recepción = this.patientToPatch.memcard_roles.includes('Recepción')
      this.Social = this.patientToPatch.memcard_roles.includes('Social')
      this.Inventario = this.patientToPatch.memcard_roles.includes('Inventario')
      this.Caja = this.patientToPatch.memcard_roles.includes('Caja')
      this.name = this.patientToPatch.nombre
      this.surname = this.patientToPatch.surname
      this.surname2 = this.patientToPatch.surname2
      this.dni = this.patientToPatch.dni
      this.signature = this.patientToPatch.signature
      this.birthdate = this.patientToPatch.birthday
        ? new Date(this.patientToPatch.birthday * 1000)
        : null
      this.tlf = this.patientToPatch.telefono
      this.country_code = this.patientToPatch.country_code
      this.email = this.patientToPatch.email
      this.notes = this.patientToPatch.notes
      this.number = this.patientToPatch.number
      this.type_member = this.patientToPatch.type
      this.consum_forecast = this.patientToPatch.consum_forecast
      this.endorsement_member = this.patientToPatch.endorsement_member
      this.keychain = this.patientToPatch.keychain
      this.photo = this.patientToPatch.foto
        ? this.patientToPatch.foto.url
        : null
      this.memcard_contract = this.patientToPatch.memcard_contract
      this.end_date = this.patientToPatch.end_date
        ? new Date(this.patientToPatch.end_date * 1000)
        : null
      this.status_member = this.patientToPatch.status_member
      this.memcard_address = this.patientToPatch.memcard_address
        ? this.patientToPatch.memcard_address
        : {}
      this.online_account = this.patientToPatch.online_account
      this.is_staff = this.patientToPatch.is_staff
      this.category_member = this.patientToPatch.category_member
        ? this.patientToPatch.category_member.nid
        : null

      this.selectedPatients = this.patientToPatch.endorsement_member
      this.selectedPatients.forEach(member => {
        member.nombre = member.name
      })
      this.negative_wallet = this.patientToPatch.monedero_negativo
      this.documents = this.patientToPatch.documents
    }
  },
  watch: {
    // dropFiles() {
    //   this.$store
    //     .dispatch('patients/createFile', {
    //       files: this.dropFiles[0],
    //       asocid: this.currentClub.nid
    //     })
    //     .then(response => {
    //       console.log(response)
    //       this.createdFids.push(response.data[0].fid)
    //     })
    //     .catch(error => {
    //       tools.manageAxiosError(error)
    //       console.log('Ha habido un error:' + error)
    //     })
    // },
    category_member() {
      console.log(this.category_member)
    },
    is_staff() {
      if (this.is_staff == true) {
        this.online_account = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 80%;
  margin: auto;
  text-align: left;
}

.content-slim {
  width: 700px;
  margin: auto;
  // text-align: left;
}

.flex {
  display: flex;
}
</style>
