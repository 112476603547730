import session from '@/services/Session.js'
// import axios from 'axios'

export default {
  sessiontoken() {
    return session.get('/services/session/token')
  },
  serverVueVersion() {
    return session.get('/api/vue_version')
  },
  login(username, password) {
    let object = { username: username, password: password }
    // return axios.post('https://stg-app.smokapp.com/api/user/login', object)
    return session.post('/api/user/login', object)
  },
  logout() {
    return session.post('/api/user/logout')
  }
}
